<template>
  <div>
    <div v-if="shouldMakeProformaInvoice.shouldShowMessage" class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Proformafactuur</h2>
      {{ shouldMakeProformaInvoice.reason}} Klik
      <router-link :to="{ name: 'PropertyInvoice' }" target="_blank" class="link tw-font-semibold tw-underline">hier</router-link>
      om een proformafactuur aan te maken.
    </div>

    <div class="tw-mb-8 tw-pb-4">
      <h2>Publicaties</h2>
      <PortalPublications
        ref="portalPublications"
        :property="property"
        :marketing="marketing"
      />
    </div>
  </div>
</template>

<script>
import PortalPublications from '@/components/properties/PortalPublications'

import { getPropertyMarketing } from '@/services/properties'

export default {
  name: 'WizardSalesAgreementStep4',
  components: {
    PortalPublications
  },
  props: {
    property: {
      type: [Number, String],
      required: true
    },
    shouldMakeProformaInvoice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      marketing: {}
    }
  },
  created () {
    this.fetchPropertyMarketing()
  },
  methods: {
    async fetchPropertyMarketing () {
      const response = await getPropertyMarketing(this.property.id)
      this.marketing = response.data
      return response
    }
  }
}
</script>
